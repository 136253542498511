@import ../../../styles/helpers

.user
    padding: 32px 28px
    border-radius: 16px
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12)
    background: $neutrals8
    border: 1px solid $neutrals6
    text-align: center
    +dark
        background: $neutrals2
        border-color: $neutrals3

.avatar
    width: 160px
    height: 160px
    margin: 0 auto 24px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.name
    margin-bottom: 4px
    +body-bold-1

.code
    display: inline-flex
    align-items: center
    margin-bottom: 20px

.number
    +button-2
    color: $neutrals2
    +dark
        color: $neutrals8

.copy
    margin-left: 8px
    svg
        fill: $blue
        transition: fill .2s
    &:hover
        svg
            fill: darken($blue, 10)

.info
    margin-bottom: 20px
    +caption-2
    color: $neutrals4

.site
    display: inline-flex
    align-items: center
    margin-bottom: 48px
    +button-2
    color: $neutrals2
    +dark
        color: $neutrals8
    svg
        margin-right: 8px
        fill: $neutrals4

.control
    display: inline-block
    position: relative
    margin-bottom: 48px

.box
    position: absolute
    left: 50%
    bottom: calc(100% + 8px)
    z-index: 2
    width: 220px
    transform: translateX(-50%)
    padding: 32px 16px
    background: $neutrals8
    border: 1px solid $neutrals6
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12)
    border-radius: 16px
    text-align: center
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: $neutrals2
        border-color: $neutrals3
    &.active
        visibility: visible
        opacity: 1

.stage
    margin-bottom: 24px
    font-weight: 500

.share
    display: flex
    justify-content: center

.direction
    span
        display: flex
        justify-content: center
        align-items: center
        flex-shrink: 0
        width: 48px
        height: 48px
        border-radius: 50%
        background: $neutrals6
        transition: all .2s
        svg
            transition: fill .2s
        &:hover
            background: $blue
            svg
                fill: $neutrals8
        +dark
            background: $neutrals3
            svg
                fill: $neutrals8
            &:hover
                background: $blue
                svg
                    fill: $neutrals8
    &:not(:last-child)
        margin-right: 24px

.btns
    display: flex
    justify-content: center
    align-items: center

.button
    span
        &:nth-child(2)
            display: none
    &.active
        background: $blue
        box-shadow: inset 0 0 0 2px $blue
        +dark
            background: $blue
        svg
            fill: $neutrals8
        span
            &:first-child
                display: none
            &:nth-child(2)
                display: inline
    &:not(:last-child)
        margin-right: 8px
    
.socials
    display: flex
    justify-content: center

.social
    svg
        fill: $neutrals4
        transition: fill .2s
    &:hover
        svg
            fill: $blue
    &:not(:last-child)
        margin-right: 24px
    
.note
    margin-top: 48px
    padding-top: 48px
    border-top: 1px solid $neutrals6
    +caption-2
    color: $neutrals4
    +dark
        border-color: $neutrals3