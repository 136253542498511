@import ../../styles/helpers

.discord 
  color: white 
  font-weight: bold 
  border-radius: 24px 
  display: inline-flex 
  align-items: center 
  padding: 15px 60px 
  background-color: #7289da 
  text-decoration: none 
  &:hover 
    background-color: #6a7fc9 

.discordBtn
  margin-right: 10px
  height: 25px
  width: 25px