@import ../../../styles/helpers

.container 
    +d
        padding: 0

.title 
    font-size: 56px
    margin-bottom: 1rem
    line-height: 1.1
    font-weight: 700
    letter-spacing: -0.045rem

    +t
        font-size: 40px

.gridLayout
    display: grid
    width: 100%
    margin: 0 auto
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto
    grid-column-gap: 24px
    grid-row-gap: 24px
    margin-top: 6rem

    +t
        margin-top: 3rem

    +m
        grid-template-columns: 1fr

.card
    background-color: $white
    display: flex
    flex-direction: column
    justify-content: space-between
    border-radius: 24px

.cardTitle
    color: #8a8a8e
    margin-bottom: 8px
    color: #8a8a8e
    font-size: 21px
    line-height: 1.2
    font-weight: 500
    letter-spacing: -0.02em

.description
    position: relative
    max-width: 560px
    margin-top: 8px
    margin-bottom: 8px
    font-size: 19px
    line-height: 1.4
    font-weight: 500
    letter-spacing: -0.02em

.content   
    padding: 40px
    +t
        padding: 36px

    +m
        padding: 24px

.sushiGif
    border-top-left-radius: 24px
    border-top-right-radius: 24px
    width: 100%
    height: 100%

.accounceContainer
    position: relative

.announceContent
    position: absolute
    left: 28px
    top: auto
    right: auto
    bottom: 20px
    z-index: 1
    padding: 8px 12px
    border-radius: 4px
    background-color: $white
    color: #1d1d1f
    font-size: 12px

    +t
        left: 24px

    +m
        position: relative
        padding: 24px
        left: 0
        background-color: transparent

    
.announceImg
    width: 100%
    height: 100%
    object-fit: cover

    +m
        object-fit: contain