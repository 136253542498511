@import ../../../../styles/helpers

.title
    margin-bottom: 32px
    padding-top: 40px
    text-align: center

.info
    max-width: 240px
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        text-decoration: underline
    
.table
    margin-bottom: 32px
    padding: 24px
    border-radius: 12px
    border: 1px solid $neutrals6
    +dark
        border-color: $neutrals3

.row
    display: flex
    &:first-child
       color: $neutrals4
    &:nth-child(2)
        font-weight: 500
        .col
            &:first-child
                color: $purple
    &:not(:last-child)
        margin-bottom: 10px

.col
    &:first-child
        margin-right: auto
        padding-right: 16px
    &:nth-child(2)
        flex-shrink: 0
        width: 152px
    
.stage
    margin-bottom: 16px
    text-align: center
    +body-bold-2

.socials
    display: flex
    justify-content: center

.social
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    border: 2px solid $neutrals6
    border-radius: 50%
    transition: border-color .2s
    +dark
        border-color: $neutrals3
    svg
        fill: $neutrals4
        transition: fill .2s
    &:hover
        border-color: $blue
        svg
            fill: $blue
    &:not(:last-child)
        margin-right: 16px
