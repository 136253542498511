@import ../../styles/helpers

.container
    padding: 55px 40px 200px 40px
    background-color: $green-light
    display: grid
    grid-row-gap: 150px
    grid-template-columns: 1fr

    +d
        padding: 55px 40px 40px
        grid-row-gap: 100px
    +t
        padding-top: 55px
        padding-left: 24px
        padding-right: 24px
    +s
        padding-left: 12px
        padding-right: 12px
        grid-row-gap: 72px

