@import ../../../styles/helpers

.container 
    position: relative
    overflow: hidden
    padding: 150px 40px 0 40px
    border-radius: 32px
    background-color: $gray

    +t
        padding: 100px 24px 0 24px
        border-radius: 0px
        margin-left: -24px
        margin-right: -24px
    +s
        padding: 72px 12px 0 12px
        margin-left: -12px
        margin-right: -12px

.flex 
    display: flex
    flex-direction: column

.content 
    display: flex
    margin: auto
    align-items: center
    text-align: center
    justify-content: center
    flex-direction: column


.logo
    display: inline-block
    max-width: 35%
    margin-bottom: 3%

.title 
    margin-bottom: 2rem
    margin-top: 12px
    line-height: 1.05
    font-weight: 700
    font-size: 80px
    color: $black

    +t
        font-size: 64px

    +m
        font-size: 40px

.description 
    color: $black
    max-width: 40rem
    font-size: 24px
    line-height: 1.3
    letter-spacing: -0.03rem
    font-weight: 500
    margin-bottom: 1rem
    margin-top: 4px

    +t
        font-size: 20px
        line-height: 1.4211
    +s
        max-width: 20rem

.buttonContainer 
    position: relative
    display: flex
    margin-top: 1.75rem

.button 
    font-size: 20px
    padding: 16px 28px
    line-height: 1.25
    height: auto
    border-radius: 100px

.avatarsContainer 
    margin-top: 85px
    max-width: 54rem
    margin-left: auto
    margin-right: auto

    +d
        max-width: 40rem
    +t
        margin-top: 50px
        max-width: 33rem

.avatars
    opacity: 0.47
    width: 100%
    height: 100%
    position: relative

