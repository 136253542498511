
@import ../../../styles/helpers

.container
    display: grid
    grid-row-gap: 1.5rem
    grid-column-gap: 1.5rem
    grid-template-columns: repeat(3, 1fr)
    
    +d
        padding: 0

    +t
        grid-column-gap: 2.5rem

    +m
        grid-template-columns: repeat(1, 1fr)

.item
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    text-align: center
    +m
        padding: 0 24px
    
.title
    font-size: 20px
    font-weight: 500
    margin: 0

.description
    font-size: 17px
    width: 75%
    margin-top: 4px
    margin-bottom: 1rem
    line-height: 1.4

    +t
        width: 100%


.image
    width: 80px
    height: 80px
    padding: 10px
    margin-bottom: 0.75rem