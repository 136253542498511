@import ../../../../styles/helpers

.title
    text-align: center
    margin-bottom: 32px

.info
    margin-bottom: 32px
    +body-2
    strong
        font-weight: 500

.row
    display: flex
    padding-top: 12px
    &:first-child
        padding: 0 0 12px
        border-bottom: 1px solid $neutrals6
        font-weight: 500
        +dark
            border-color: $neutrals3
        .col
            color: $neutrals2
            +dark
                color: $neutrals8

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500
    
.btns
    margin-top: 32px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px

.attention
    display: flex
    align-items: center
    margin-top: 32px
    padding: 24px
    border-radius: 8px
    background: rgba($pink, .08)
    color: $pink
    +m
        align-items: stretch

.preview
    flex-shrink: 0
    width: 32px
    margin-right: 16px
    svg
        fill: $pink
    
.details
    flex-grow: 1

.subtitle
    +body-bold-2

.text
    +caption-2

.line
    display: flex
    align-items: center
    .text
        color: $neutrals4

.icon
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 40px
    height: 40px
    margin-right: 20px
    background: $neutrals6
    border: 2px solid $neutrals6
    border-radius: 50%
    transition: all .2s
    svg
        fill: $neutrals4
        transition: fill .2s
    .loader
        position: absolute
        top: -3px
        left: -3px
        right: -3px
        bottom: -3px
        background: $neutrals8
        +dark
            background: $neutrals1
    
.avatar
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-left: 16px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover