@import ../../../styles/helpers

.control
    padding: 24px
    border-radius: 16px
    box-shadow: 0px 64px 64px -24px rgba(31, 47, 70, 0.12)
    border: 1px solid $neutrals6
    +m
        margin-left: -16px
        margin-right: -16px
        padding: 16px
    +dark
        background: $neutrals2
        border-color: $neutrals3

.head
    display: flex
    align-items: center

.avatar
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 16px
    img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.info
    +body-bold-2
    color: $neutrals4
    span
        color: $neutrals2
        +dark
            color: $neutrals8

.cost
    display: flex
    
.price
    +body-bold-1
    &:nth-child(2)
        color: $neutrals4
    &:not(:last-child)
        margin-right: 12px

.btns
    display: flex
    margin: 32px -4px 0
    .button
        flex: 0 0 calc(50% - 8px)
        width: calc(50% - 8px)
        margin: 0 4px
        padding: 0 8px

.text
    margin-top: 32px
    font-weight: 500
    color: $neutrals4
    span
        margin-left: 12px
        font-weight: 400
    
.percent
    font-weight: 500
    color: $neutrals2
    +dark
        color: $neutrals8

.foot
    margin-top: 32px
    .button
        width: 100%
    
.note
    margin-top: 32px
    color: $neutrals4