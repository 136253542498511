@import ../../../../styles/helpers

.wrap
    max-width: 500px
    justify-content: center
    align-items: center
    margin-bottom: 16px
    margin: auto
    padding: 30px 16px
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)
    border: 2px solid $neutrals6
    border-radius: 24px
    text-align: center
    +d
        margin-bottom: 8px
        padding: 24px 16px
    +m
    +a
        max-width: 350px
        margin-bottom: -24px
    +dark
        background: $neutrals2
        border-color: $neutrals2

.info
    margin-bottom: 18px
    +body-bold-2

.currency
    +dm-sans
    font-size: 36px
    line-height: (56/48)
    letter-spacing: -.02em

.price
    margin-bottom: 24px
    +body-bold-1
    color: $neutrals4

.btns
  display: flex
  flex-direction: column
  .button
      width: 100%
      &:not(:last-child)
          margin-bottom: 8px