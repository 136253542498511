@import ../../styles/helpers

.container

.title 
  font-size: 48px
  margin-bottom: 50px
  margin-top: 50px
  line-height: 1.1
  font-weight: 700
  letter-spacing: -0.045rem
  +t
    font-size: 40px
  text-align: center

.gridContainer
  padding: 10px 10px 10px 10px
  display: grid
  grid-template-columns: 1fr 1.5fr
  grid-template-rows: auto
  grid-gap: 20px
  width: 100%
  +t
    grid-template-columns: 1fr
  +m
    grid-template-columns: 1fr


.imagebox
  display: flex
  flex-direction: column
  justify-content: center
  border-radius: 24px
  text-align: center
  border-radius: 24px
  align-items: center

.image
  width: 100%
  height: 100%
  max-height: 500px
  min-width: 400px
  max-width: 500px
  border-radius: 24px

.formselect
  display: flex
  flex-direction: column
  border-radius: 24px
  text-align: center
  align-items: center
  justify-content: center

.selectitem
  display: inline-block
  max-height: 500px
  min-width: 400px
  max-width: 500px
  width: 75%
  padding: 10px 10px
  text-align: center

.selecttitle
  color: #000000
  margin-bottom: 8px
  font-size: 21px
  line-height: 1.2
  font-weight: 500
  letter-spacing: -0.02em
  text-align: left

.scoretitle
  color: #ff0000
  margin-bottom: 8px
  margin-top: 15px
  font-size: 21px
  line-height: 1.2
  font-weight: 500
  letter-spacing: -0.02em
  text-align: left

.submit
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 50px
  margin-bottom: 10px

.address
  width: 40%
  margin-bottom: 25px
  margin-top: 25px

.discord
  width: 40%
  margin-bottom: 25px

.sbutton
  margin-bottom: 25px

.btns
  display: flex
  margin-top: 40px
  padding-top: 40px
  border-top: 1px solid $neutrals6
  +m
      display: block
      text-align: center
  +dark
      border-color: $neutrals3
  .button
      +m
          width: 100%
      &:not(:last-child)
          margin-right: 32px
          +m
              margin: 0 0 32px





