@import ../../../../styles/helpers

.wrap
    max-width: 500px
    justify-content: center
    align-items: center
    margin-bottom: 16px
    margin: auto
    padding: 30px 16px 30px
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)
    border: 2px solid $neutrals6
    border-radius: 24px
    text-align: center
    +d
        margin-bottom: 8px
        padding: 24px 16px
    +m
    +a
        max-width: 350px
        margin-bottom: -24px
    +dark
        background: $neutrals2
        border-color: $neutrals2

.info
    color:#b1b5c3
    text-align: left
    font-weight: 500
    margin-bottom: 16px
    //+body-bold-2

.networkInfo
    color:#b1b5c3
    text-align: center
    font-weight: 500
    margin-bottom: 0px
    margin-top: 10px
    //+body-bold-2

.maxmint
    color:#999999
    text-align: center
    font-weight: 500
    margin-bottom: 16px
    //+body-bold-2

.currency
    color:#999999
    +dm-sans
    font-size: 36px
    line-height: (56/48)
    letter-spacing: -.02em

.price
    margin-top: 8px
    margin-bottom: 4px
    +body-bold-1
    color: $neutrals4

.btns
    display: flex
    flex-direction: column
    .button
        width: 100%
        background-color: #B1379E
        &:not(:last-child)
            margin-bottom: 8px
        &:disabled
            background-color: #808080

.contract
    margin-top: 25px
    text-align: center
    justify-content: flex-end
    align-items: flex-end
    a
        &:link 
            color: black
            text-decoration: underline
        &:visited 
            color: black
            text-decoration: underline
        &:hover 
            color: black
            text-decoration: underline
        &:active 
            color: black
            text-decoration: underline
