@import ../../../../styles/helpers

.wrap
  max-width: 500px
  justify-content: center
  align-items: center
  margin-bottom: 16px
  padding: 30px 16px
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)
  border: 2px solid $neutrals6
  border-radius: 24px
  text-align: center
  +d
      margin-bottom: 8px !important
      padding: 24px 16px
  +t
      margin-bottom: 16px !important
  +m
      margin-bottom: 16px
  +a
      max-width: 350px
      margin-bottom: 16px !important
  +dark
      background: $neutrals2
      border-color: $neutrals2

.info
  margin-bottom: 18px
  +body-bold-2

.timer
  display: flex
  justify-content: center
  margin-top: 8px

.box
  flex-shrink: 0
  width: 64px
  &:not(:last-child)
      margin-right: 20px

.number
  +dm-sans
  font-size: 32px
  line-height: (40/32)
  letter-spacing: -.01em

.time
    +body-bold-2
    color: $neutrals4