.counterBtn
    border: 0 !important

.minBtn
    border: 2px solid #777e90 !important
    border-radius: 5px !important    
    line-height: 0 !important
    font-size: 2rem !important
    padding: 5px 0px 10px 0px !important
    min-width: 25px !important
    align-items: center
    color: #777e90 !important
    margin: 20px 0px !important

.addBtn
    border: 2px solid #777e90 !important
    border-radius: 5px !important    
    line-height: 0 !important
    font-size: 1.6rem !important
    padding: 5px 0px 10px 0px !important
    min-width: 25px !important
    align-items: center   
    color: #777e90 !important 
    margin: 20px 0px !important

.counterBtn
    font-size: 1.6rem !important
    color: #000 !important
    font-weight: 600 !important