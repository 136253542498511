@import ../../../styles/helpers

.title
    margin-bottom: 32px

.item
    &.error
        .icon
            border-color: $pink
            background: transparent
            svg
                fill: $pink
    &.done
        .icon
            border-color: $green
            background: $green url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 50% / 14px auto
            svg
                display: none
    &:not(:last-child)
        margin-bottom: 32px
    
.head
    display: flex
    align-items: center
    margin-bottom: 16px

.icon
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 20px
    background: $neutrals6
    border: 2px solid $neutrals6
    border-radius: 50%
    transition: all .2s
    svg
        fill: $neutrals4
        transition: fill .2s
    +dark
        background: $neutrals3
        border-color: $neutrals3
    .loader
        position: absolute
        top: -2px
        left: -2px
        right: -2px
        bottom: -2px
        background: $neutrals8
        +dark
            background: $neutrals1

.info
    +body-bold-2

.text
    +caption-2
    color: $neutrals4

.button
    width: 100%

.note
    margin-top: 16px
    +caption-2
    color: $neutrals4
    a
        color: $blue
        &:hover
            text-decoration: underline