.container
    width: 100%
    max-width: 1280px
    margin: 0 auto
    padding: 0px
    +d
        padding: 0 40px
    +t
        padding: 0 40px
    +m
        padding: 0 32px
    +s
        padding: 0 24px
    